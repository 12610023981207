import api from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import setAuthToken from "../utils/setAuthToken"
import jwt_decode from "jwt-decode"


// GET CURRENT USER WITH TOKEN AND MOVE TO INITIAL STATE
const token = localStorage.getItem("jwtToken")
const decoded = token ? jwt_decode(token) : {}

// Check for expired token
const currentTime = Date.now() / 1000; // to get in milliseconds
if (decoded.exp < currentTime) {
  // Logout user
  localStorage.removeItem("jwtToken")
  setAuthToken(false)

  // Redirect to login
  window.location.href = "./login";
}

const initialState = {
  isAuthenticated: token ? true : false,
  activated: decoded.activated,
  user: decoded,
  loading: false,
  errors: {}
};


export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async ({userData, history}, thunkAPI) => {
    try {
      const res = await api.registerUser(userData)
      const { token } = res.data;
      localStorage.setItem("jwtToken", token)
      history.push("/activate")
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      return decoded

    } catch (e) {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (userData, thunkAPI) => {
    try {
      const res = await api.loginUser(userData)
      const { token } = res.data;
      localStorage.setItem("jwtToken", token)
      // Set token to Auth header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      return decoded

    } catch (e) {
      console.log('Error in login', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const activateUser = createAsyncThunk(
  'auth/activateUser',
  async (activationToken, thunkAPI) => {
    try {
      const res = await api.getMatchingActivationToken(activationToken)
      const { token } = res.data;
      localStorage.setItem("jwtToken", token)
      // Set token to Auth header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      return decoded

    } catch (e) {
      console.log('Error in activation', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetchUserByToken: (state, action) => {
      // const token = localStorage.getItem("jwtToken")
      const token = action.payload
      state.user = token ? jwt_decode(token): {}
      console.log("FETCH USER", state.user)
      state.isAuthenticated = token ? true : false
      state.activated = state.user.activated ? true : false
      state.loading = false
      return state
    },
    logoutUser: (state) => {
      localStorage.removeItem("jwtToken")
      setAuthToken(false)
      state.isAuthenticated = false
      state.activated = false
      state.user = {}
      // reload the page to ensure access is correct
      window.location.reload()
      return state
    }
  },
  extraReducers: {
    [registerUser.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;
      state.activated = payload.activated
      state.errors = {}
      state.loading = false
      return state;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.errors = payload
      state.loading = false
      return state;
    },
    [registerUser.pending]: (state) => {
      state.loading = true;
    },

    [loginUser.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;
      state.activated = payload.activated
      state.errors = {}
      state.loading = false
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isAuthenticated = false;
      state.activated = false
      state.errors = payload
      state.loading = false
      return state;
    },
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [activateUser.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;
      state.activated = payload.activated
      state.errors = {}
      // state.loading = false
      return state;
    },
    [activateUser.rejected]: (state, { payload }) => {
      state.errors = payload
      // state.loading = false
      return state;
    },
    [activateUser.pending]: (state) => {
      // state.loading = true;
    },
  },
});

export const { logoutUser } = authSlice.actions;

export const authSelector = (state) => state.auth;

export const authReducer = authSlice.reducer