import axios from 'axios'

// const apiURL = process.env.PORT || 'http://localhost:5000/api' 

const apiURL = process.env.REACT_APP_API_URL
// const apiURL = (process.env.NODE_ENV === 'production' ? 'http://tr4velapp.azurewebsites.net/api' : 'http://localhost:5000/api')

const api = axios.create({
    baseURL: apiURL,
})

// const setAuthToken = token => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      // Apply authorization token to every request if logged in
      api.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete auth header
      delete api.defaults.headers.common["Authorization"];
    }
//   };
  

// SEARCH
export const searchYelp = (term, lat, long) => api.get('/search/keyword', {
    params: {
        "term": term,
        "lat": lat,
        "long": long
    }
});

export const getYelpBusinessDetails = (business_id) => api.get('/search/business-id', {
    params: {
        "id": business_id
    }
});

export const getYelpReviews = (business_id) => api.get('/yelp-reviews', {
    params: {
        "id": business_id
    }
});

// ACTIVITY
export const createActivity = payload => api.post(`/activity`, payload)
export const getAllActivities = () => api.get(`/activities`)
export const updateActivityById = (id, payload) => api.put(`/activity/${id}`, payload)
export const deleteActivityById = id => api.delete(`/activity/${id}`)
export const getActivityById = id => api.get(`/activity/${id}`)
export const yelpAutocomplete = (query) => api.get(`/yelp/autocomplete/`, { params: query })

// ITINERARY
export const createItinerary = payload => api.post(`/itinerary`, payload)
export const createItineraryScratch = payload => api.post(`/scratch/itinerary`, payload)
export const getAllItineraries = () => api.get(`/itineraries`)
export const updateItineraryById = (id, payload) => api.put(`/itinerary/${id}`, payload)
export const updateItineraryItemById = (id, payload) => api.put(`/item/itinerary/${id}`, payload)
// export const addItineraryActivityByGoogleById = (id, payload) => api.post(`/itinerary/activity/${id}`, payload)
export const addItineraryActivityByYelpById = (id, payload) => api.post(`/itinerary/activity/${id}`, payload)
export const deleteItineraryActivityById = id => api.delete(`/itinerary/activity/${id}`)
export const deleteItineraryById = id => api.delete(`/itinerary/${id}`)
export const getItineraryById = id => api.get(`/itinerary/${id}`)
export const getItinerariesByUserId = id => api.get(`/itineraries/${id}`)
export const rerollItineraryActivityById = id => api.post(`/itinerary/rerollactivity/${id}`)

export const getYCItineraries = () => api.get(`/yc`)

// PUBLISHED ITINERARY
// export const publishItinerary = payload => api.post(`/publish/itinerary`, payload)
// export const unpublishItinerary = id => api.put(`/unpublish/itinerary/${id}`)
// export const getAllPublishedItineraries = () => api.get(`/publish/itineraries`)
export const getPublishedItinerariesPaged = page => api.get(`/publish/itineraries/paged/${page}`)
export const getPublishedItinerariesFilteredPaged = (page, query) => api.get(`/publish/itineraries/filtered/paged/${page}`, { params: query })
export const copyItinerary = payload => api.post(`/copy/itinerary`, payload)
    // NOT SUPER POLISHED
export const likeItinerary = payload => api.post(`/like/itinerary`, payload)
export const unlikeItinerary = payload => api.post(`/unlike/itinerary`, payload)
export const getItinerariesByUsername = user_name => api.get(`username/itineraries/${user_name}`)


// USER
export const loginUser = payload => api.post(`/user/login`, payload)
export const registerUser = payload => api.post(`/user/register`, payload)
export const createUser = payload => api.post(`/user`, payload)
export const getAllUsers = () => api.get(`/users`)
export const updateUserById = (id, payload) => api.put(`/user/${id}`, payload)
export const deleteUserById = id => api.delete(`/user/${id}`)
export const getUserById = id => api.get(`/user/${id}`)
    // NOT SUPER POLISHED
export const followUser = payload => api.post(`/user/follow`, payload)
export const unfollowUser = payload => api.post(`/user/unfollow`, payload)
export const autocompleteUser = query => api.get(`/autocomplete/user/${query}`)

export const getMatchingPasswordToken = token => api.get(`/passwordtoken/${token}`)
export const getMatchingActivationToken = token => api.get(`/activationtoken/${token}`)
export const resetPassword = payload => api.post(`/user/resetpassword`, payload)
export const recoverPassword = payload => api.post(`/user/recoverpassword`, payload)
export const resendActivationEmail = payload => api.post(`/user/activationemail`, payload)

const apis = {
    searchYelp,
    getYelpBusinessDetails,
    getYelpReviews,

    createActivity,
    getAllActivities,
    updateActivityById,
    deleteActivityById,
    getActivityById,
    yelpAutocomplete,

    createItinerary,
    createItineraryScratch,
    getAllItineraries,
    updateItineraryById,
    updateItineraryItemById,
    // addItineraryActivityByGoogleById,
    addItineraryActivityByYelpById,
    deleteItineraryActivityById,
    rerollItineraryActivityById,
    deleteItineraryById,
    getItineraryById,
    getItinerariesByUserId,
    getYCItineraries,

    // publishItinerary,
    // unpublishItinerary,
    // getAllPublishedItineraries,
    getPublishedItinerariesPaged,
    getPublishedItinerariesFilteredPaged,
    copyItinerary,

    likeItinerary,
    unlikeItinerary,
    getItinerariesByUsername,

    loginUser,
    registerUser,
    createUser,
    getAllUsers,
    updateUserById,
    deleteUserById,
    getUserById,

    followUser,
    unfollowUser,
    autocompleteUser,
    getMatchingPasswordToken,
    getMatchingActivationToken,
    resetPassword,
    recoverPassword,
    resendActivationEmail
}

export default apis