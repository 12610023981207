// import ActivityCard from './ActivityCard/ActivityCard'
// import ItineraryCard from './ItineraryCard/ItineraryCard'
// import Loading from './Loading/Loading'
// import LocationPin from './LocationPin/LocationPin'
// import Map from './Map/Map'
// import MapSearchResultCard from './MapSearchResultCard/MapSearchResultCard'
// import NavBar from './NavBar/NavBar'
// import Notifications from './Notifications/Notifications'
// import PrivateRoute from './PrivateRoute/PrivateRoute'
// import PublishedItineraryCard from './PublishedItineraryCard/PublishedItineraryCard'
// import SearchInput from './SearchInput/SearchInput'
// import YelpAutocomplete from './YelpAutocomplete/YelpAutocomplete'
// import MapSearchResultModal from './MapSearchResultModal/MapSearchResultModal'
import {lazy} from 'react'
const ActivityCard = lazy(() => import('./ActivityCard/ActivityCard'))
const ItineraryCard = lazy(() => import('./ItineraryCard/ItineraryCard'))
const Loading = lazy(() => import('./Loading/Loading'))
const LocationPin = lazy(() => import('./LocationPin/LocationPin'))
const Map = lazy(() => import('./Map/Map'))
const MapSearchResultCard = lazy(() => import('./MapSearchResultCard/MapSearchResultCard'))
const NavBar = lazy(() => import('./NavBar/NavBar'))
const Notifications = lazy(() => import('./Notifications/Notifications'))
const PrivateRoute = lazy(() => import('./PrivateRoute/PrivateRoute'))
const PublishedItineraryCard = lazy(() => import('./PublishedItineraryCard/PublishedItineraryCard'))
const SearchInput = lazy(() => import('./SearchInput/SearchInput'))
const YelpAutocomplete = lazy(() => import('./YelpAutocomplete/YelpAutocomplete'))
const MapSearchResultModal = lazy(() => import('./MapSearchResultModal/MapSearchResultModal'))

export { 
    ActivityCard, ItineraryCard, Loading, LocationPin, Map, MapSearchResultCard, NavBar,
    Notifications, PrivateRoute, PublishedItineraryCard, SearchInput,
    YelpAutocomplete, MapSearchResultModal
}
