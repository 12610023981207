import React from 'react';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { NavBar, PrivateRoute } from '../components'
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './custom.scss'

import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module'

const About = React.lazy(() => import('../pages/About'))
const CreateItinerary = React.lazy(() => import('../pages/CreateItinerary'))
const ListItineraries = React.lazy(() => import('../pages/ListItineraries'))
const Explore = React.lazy(() => import('../pages/Explore'))
const PitchPage = React.lazy(() => import('../pages/PitchPage'))
const ViewItinerary = React.lazy(() => import('../pages/ViewItinerary'))
const Register = React.lazy(() => import('../pages/Register'))
const Login = React.lazy(() => import('../pages/Login'))
const NotFound = React.lazy(() => import('../pages/NotFound'))
const Inactive = React.lazy(() => import('../pages/Inactive'))
const PasswordReset = React.lazy(() => import('../pages/PasswordReset'))
const PasswordChange = React.lazy(() => import('../pages/PasswordChange'))
const Activation = React.lazy(() => import('../pages/Activation'))

const tagManagerArgs = {
    gtmId: 'GTM-PPVPMDP'
}

TagManager.initialize(tagManagerArgs)

const theme = createTheme({
    palette: {
        primary: {
            main: "#6f12e9",
        }
    },
    typography: {
        "fontFamily": `"Red Hat Display", sans-serif`,
    },
    alignItems: 'center',
});

function App() {
    return (
        <div>
            <Helmet>
                <style>{'body { background-color: #fff; }'}</style>
            </Helmet>
            <ThemeProvider theme={theme}>
                <Router>
                    <Suspense fallback={<div></div>}>
                        <NavBar />
                        <Switch>

                            {/* BASE */}
                            <Route exact path="/">
                                <Redirect to="/explore" />
                            </Route>

                            {/* ABOUT */}
                            <Route path="/about" component={About} />

                            {/* CREATE ITINERARY */}
                            <PrivateRoute path="/create" component={CreateItinerary} />

                            {/* VIEW ITINERARY */}
                            <Route exact path="/itinerary/:id" component={ViewItinerary} />

                            {/* ITINERARY LIST */}
                            <Route exact path="/user/:user_name" component={ListItineraries} />

                            {/* EXPLORE ITINERARIES */}
                            <Route path="/explore" component={Explore} />

                            {/* YC ITINERARIES */}
                            <Route path="/YC"
                                render={(props) => (
                                    <PitchPage {...props} pitchee="YC" />
                                )} />

                            {/* BASES ITINERARIES */}
                            <Route path="/bases"
                                render={(props) => (
                                    <PitchPage {...props} pitchee="BASES" />
                                )} />

                            {/* RESET PASSWORD */}
                            <Route path="/passwordreset" component={PasswordReset} />

                            {/* CHANGE PASSWORD */}
                            <Route path="/passwordchange/:token" component={PasswordChange} />

                            {/* ACTIVATE ACCOUNT */}
                            <Route path="/activate" component={Inactive} />

                            {/* ACTIVATION LANDING PAGE */}
                            <Route path="/activation/:token" component={Activation} />

                            {/* REGISTER */}
                            <Route path="/register" component={Register} />

                            {/* LOGIN */}
                            <Route path="/login" component={Login} />

                            <Route component={NotFound} />

                        </Switch>
                    </Suspense>
                </Router>
            </ThemeProvider>
        </div>
    )
}

export default App