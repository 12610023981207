import api from '../api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// HELPERS
function getTomorrowDate() {
  let output = new Date()
  output.setDate(output.getDate() + 1)
  output.setHours(0, 0, 0, 0)
  return output
}

// new_start_date defaults to tomorrow's date
// TODO EDIT FOR MULTI_DAY TRIPS (USE OFFSET INSTEAD)
function shiftTripStart(itinerary, new_start_date = getTomorrowDate()) {
  itinerary.start_date = new Date (new_start_date.getTime()).toISOString()
  itinerary.end_date = new Date (new_start_date.getTime()).toISOString()

  itinerary.activities = itinerary.activities.map(activity => {
    const old_date_time = new Date(activity.date_time)
    let new_date_time = new Date (new_start_date.getTime())
    new_date_time.setUTCHours(old_date_time.getUTCHours(), old_date_time.getUTCMinutes())
    activity.date_time = new_date_time.toISOString()
    return activity
  })
  return itinerary
}
// --------

const initialState = {
    loading: true,
    itinerary: {},
    access: null,
    error: null,
  };


  // needed functions: get itin, update itin, add by yelp id, update cost, reroll, delete activity, delete itin?
export const getItinerary = createAsyncThunk(
  'viewItinerary/getItineary',
  async (itineraryId, thunkAPI) => {
    try {
        const res = await api.getItineraryById(itineraryId)
        const itinerary = res.data.data
        const access = res.data.access
        return {itinerary, access}
    }
    catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)


// updating nickname, description, tags, date, publish
// use same syntax and objects as used in the actual API call
export const updateItinerary = createAsyncThunk(
  'viewItinerary/updateItineary',
  async ({itineraryId, payload}, thunkAPI) => {
    try {
        const res = await api.updateItineraryById(itineraryId, payload)
        const itinerary = res.data
        return itinerary
    }
    catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

export const addActivityByYelpId = createAsyncThunk(
  'viewItinerary/addActivityByYelpId',
  async ({itineraryId, payload}, thunkAPI) => {
    try {
        const res = await api.addItineraryActivityByYelpById(itineraryId, payload)
        // TODO add in response on the backend so it returns an new itinerary
        const itinerary = res.data
        return itinerary
    }
    catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

export const deleteActivity = createAsyncThunk(
  'viewItinerary/deleteActivity',
  async ({activityId}, thunkAPI) => {
    try {
        await api.deleteItineraryActivityById(activityId)
        return
    }
    catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

export const rerollActivity = createAsyncThunk(
  'viewItinerary/rerollActivity',
  async ({activityId}, thunkAPI) => {
    try {
        const res = await api.rerollItineraryActivityById(activityId)
        const itinerary = res.data
        return itinerary
    }
    catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

export const updateItineraryItem = createAsyncThunk(
  'viewItinerary/updateItineraryItem',
  async ({itineraryId, payload}, thunkAPI) => {
    try {
        const res = await api.updateItineraryItemById(itineraryId, payload)
        const itinerary = res.data
        return itinerary
    }
    catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

export const viewItinerarySlice = createSlice({
    name: 'viewItinerary',
    initialState,
    reducers: {
    },
    extraReducers: {

      // GET ITINERARY

      [getItinerary.fulfilled]: (state, { payload }) => {
        let itinerary = payload.itinerary
        itinerary.activities = itinerary.activities.sort((a_item, b_item) => (a_item.date_time > b_item.date_time) ? 1 : -1)
        
        state.loading = false;
        state.itinerary = itinerary;
        state.access = payload.access;
        return state
      },
      [getItinerary.pending]: (state) => {
        state.loading = true;
      },
      [getItinerary.rejected]: (state, { payload }) => {
        state.loading = false;
        state.error = payload.error;
      },

      // UPDATE ITINERARY

      [updateItinerary.fulfilled]: (state, { payload }) => {
        let itinerary = payload.itinerary
        itinerary.activities = itinerary.activities.sort((a_item, b_item) => (a_item.date_time > b_item.date_time) ? 1 : -1)
        state.itinerary = itinerary;
        // state.access = payload.access;
        return state
      },
      [updateItinerary.pending]: (state, action) => {
        const body = action.meta.arg.payload
        let itinerary = state.itinerary

        if (body.published !== undefined) { itinerary.published = body.published }
        if (body.nickname !== undefined) { itinerary.nickname = body.nickname }
        // if (body.collaborators !== undefined) {itinerary.collaborators = body.collaborators}
        // if (body.est_cost !== undefined) { itinerary.est_cost = body.est_cost }
        if (body.start_date !== undefined) {
            const new_start_date = new Date (Date.parse(`${body.start_date}T00:00:00.000Z`))
            itinerary = shiftTripStart(itinerary, new_start_date)
        }
        // if (body.destination !== undefined) { itinerary.destination = body.destination }
        if (body.description !== undefined) { itinerary.description = body.description }
        // if (body.views !== undefined) { itinerary.views = body.views }
        // if (body.copies !== undefined) { itinerary.copies = body.copies }
        // if (body.inspiration !== undefined) { itinerary.inspiration = body.inspiration }
        // if (body.likes !== undefined) { itinerary.likes = body.likes }
        // if (body.activities !== undefined) { itinerary.activities = body.activities }
        // if (body.comments !== undefined) { itinerary.comments = body.comments }
        if (body.tags !== undefined) { itinerary.tags = body.tags }
        state.itinerary = itinerary
        // state.loading = true;
      },
      [updateItinerary.rejected]: (state, { payload }) => {
        console.log("PAYLOAD", payload)
        // state.error = payload.error;
      },

      // ADD ACTIVITY

      [addActivityByYelpId.fulfilled]: (state, { payload }) => {
        console.log(payload)
        let itinerary = payload.itinerary
        itinerary.activities = itinerary.activities.sort((a_item, b_item) => (a_item.date_time > b_item.date_time) ? 1 : -1)
        state.itinerary = itinerary

        state.loading = false;
        return state
      },
      // Optimistically make changes on frontend before response
      [addActivityByYelpId.pending]: (state, action) => {
        console.log("ARRRRR", action.meta.arg.payload)
        // const body = action.meta.arg.payload
        // state.loading = true
      },
      [addActivityByYelpId.rejected]: (state, { payload }) => {
        state.loading = false;
        console.log("PAYLOAD", payload)
        state.error = payload.error;
      },

      // DELETE ACTIVITY

      [deleteActivity.fulfilled]: (state, { payload }) => {
        console.log(payload)
        // let itinerary = payload.itinerary
        // state.itinerary = itinerary

        state.loading = false;
        return state
      },
      [deleteActivity.pending]: (state, action) => {
        console.log("ARRRRRGGG", action.meta.arg.activityId)
        const activityId = action.meta.arg.activityId
        let new_activities = state.itinerary.activities
        console.log(new_activities)
          for (var i = 0; i < new_activities.length; i++) {
              if (new_activities[i]._id === activityId) {
                  new_activities.splice(i, 1);
                  break
              }
          }
          console.log("GOT HERE")

        state.itinerary.activities = new_activities
      },
      [deleteActivity.rejected]: (state, { payload }) => {
        state.loading = false;
      },

      // // REROLL

      [rerollActivity.fulfilled]: (state, { payload }) => {
        let itinerary = payload.itinerary
        itinerary.activities = itinerary.activities.sort((a_item, b_item) => (a_item.date_time > b_item.date_time) ? 1 : -1)
        state.itinerary = itinerary

        state.loading = false;
        return state
      },
      // Optimistically make changes on frontend before response
      [rerollActivity.pending]: (state, action) => {
        // let itinerary = state.itinerary
        // console.log("ARGS", action.meta.arg)
        // let arg = 0
        // itinerary.activities[arg].loading = true
        // state.itinerary = itinerary
        // return state
      },
      [rerollActivity.rejected]: (state, { payload }) => {
        // state.loading = false;
        // console.log("PAYLOAD", payload)
        // state.error = payload.error;
      },

      // UPDATE ITIN ITEM BY ID

      [updateItineraryItem.fulfilled]: (state, { payload }) => {
        let itinerary = payload.itinerary
        itinerary.activities = itinerary.activities.sort((a_item, b_item) => (a_item.date_time > b_item.date_time) ? 1 : -1)
        state.itinerary = itinerary

        state.loading = false;
        return state
      },
      [updateItineraryItem.pending]: (state, action) => {
        // const body = action.meta.arg.payload
        // let itinerary = state.itinerary

        // if (body.date !== undefined && body.time !== undefined) {
        //   const date_time = Date.parse(`${body.date}T${body.time}:00.000Z`)
        //   itinerary.activities[activity_index].date_time = date_time
        // }
        // // if (body.activity) { itinerary.activities[activity_index].activity = body.activity }
        // if (body.note !== undefined) { itinerary.activities[activity_index].note = body.note }
        // if (body.est_cost_override !== undefined) {
        //     if (err) console.log(err) // handleError
        //     old_est_cost = (itinerary.activities[activity_index].est_cost_override !== undefined) ? itinerary.activities[activity_index].est_cost_override : (price_mapping_num[activity.price])
        //     // console.log(old_est_cost)
        //     itinerary_price_delta = (body.est_cost_override - old_est_cost)
        //     // console.log(itinerary_price_delta)
        //     itinerary.activities[activity_index].est_cost_override = body.est_cost_override
        //     itinerary.est_cost = itinerary.est_cost + itinerary_price_delta
        // }

        // state.itinerary = itinerary
      },
      [updateItineraryItem.rejected]: (state, { payload }) => {
        // state.loading = false;
        // console.log("PAYLOAD", payload)
        // state.error = payload.error;
      },
    }
  })

  // export const selectItinerary = ({ viewItinerary }) => viewItinerary

  export default viewItinerarySlice.reducer